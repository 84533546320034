<template>
	<section class="login">
		<template v-if="$root.rwd < 2">
			<lottie class="left" :options="{ path: '/lottie/Miscanthus01/data.json', autoplay: true, loop: true }" />
			<lottie class="right" :options="{ path: '/lottie/Miscanthus02/data.json', autoplay: true, loop: true }" />
		</template>
		<div class="max-width-1000">
			<quote-title label="會員登入" />
			<br>
			<div class="row-grid" align-x="around" gutter-x="30" gutter-y="50">
				<div class="col rwd-m-6" align-y="between">
					<div>
						<h1 class="center">會員註冊</h1>
						<hr>
						<br v-if="$root.rwd < 2">
						<p>註冊將成為「金粉會員」，享票價與商品折扣優惠。</p>
					</div>
					<br>
					<div class="center">
						<router-link to="/join/member">
							<el-button type="theme" round size="big">立即加入</el-button>
						</router-link>
					</div>
				</div>
				<div class="col rwd-m-6" align-y="between">
					<div>
						<h1 class="center">會員登入</h1>
						<hr>
						<br v-if="$root.rwd < 2">
						<el-form :model="ruleForm" size="small">
							<el-form-item label="帳號：" prop="account">
								<el-input v-model="ruleForm.account" class="round" />
							</el-form-item>
							<el-form-item label="密碼：" prop="password">
								<el-input v-model="ruleForm.password" show-password class="round" @keyup.native.enter="handleLogin()" />
							</el-form-item>
							<el-form-item>
								<div class="row" align-x="between">
									<el-checkbox v-model="ruleForm.remember_me">記住登入資訊</el-checkbox>
									<router-link to="/forget-password" class="theme_text">忘記密碼？</router-link>
								</div>
							</el-form-item>
						</el-form>
					</div>
					<br>
					<div class="center">
						<el-button type="theme" round size="big" @click="handleLogin()">登{{"\u3000"}}入</el-button>
					</div>
				</div>
			</div>
			<br>
			<br>
		</div>
	</section>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { getAuth } from "@/utils/auth";
export default {
	data() {
		return {
			ruleForm: new Object
		}
	},
	methods: {
		...mapActions("auth", ["_login"]),
		async handleLogin() {
			await this._login(this.ruleForm);
			this.$message.success("登入成功");
			// 判斷跳回前一頁，或是首頁
			const { path } = this.routerFrom;	// beforeRouteEnter 裡的 vm.routerFrom
			this.$router.push(
				[
					/\/join.*/,
					/\/about.*/,
					/\/activity.*/,
					/\/news.*/,
					/\/portfolio.*/,
					/\/perform.*/,
					/\/product.*/,
					/\/profile.*/,
					/\/cart.*/,
				].some(regex => regex.test(path)) ? path : "/"
			);
		}
	},
	created() {
		const data = getAuth();
		if (data.remember_me) this.ruleForm = this._.cloneDeep(data);
	},
	beforeRouteEnter(to, from, next) {
		next(vm => {
			// 通过 `vm` 访问组件实例
			vm.routerFrom = from;	// 紀錄前一個 route
		});
	}
}
</script>

<style lang="scss" scoped>
.login {
	padding: 50px 5%;
	position: relative;
	background-size: 100%;
	background-repeat: no-repeat;
	background-image: url("../assets/background/brush1.png"),
		url("../assets/background/dot1.png");
	background-position: center bottom, center 70%;

	.lottie {
		position: absolute;
		width: 25%;
		max-width: 220px;
		&.left {
			top: 70%;
			left: 20px;
		}
		&.right {
			top: 30%;
			right: 20px;
		}
	}
}
.row-grid {
	> .col {
		max-width: 400px;
	}
}
</style>